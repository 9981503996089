<template>
  <NuxtLink
    itemscope
    itemtype="https://schema.org/Product"
    :to="path"
    :class="['product-thumb', `product-thumb-layout-${layout}`]"
    @click="$emit('onClick')"
  >
    <meta itemprop="url" :content="path" />
    <div
      class="product-thumb-image-wrap"
      :class="
        (isBlendingTaxon || isBlendingElement) &&
        'product-thumb-image-wrap-circle'
      "
    >
      <div
        v-if="
          ['default', 'blank'].includes(layout) &&
          cleanScentLevel != 'not_clean'
        "
        class="product-thumb-highlights"
      >
        <template v-if="!cleanScentsPlusEnabled">
          <CleanScents
            v-if="isCleanScent"
            level="clean_scent"
            icon="square"
            class="product-thumb-clean-scents"
          />
        </template>
        <template v-else>
          <CleanScents
            v-if="cleanScentsPlusEnabled"
            :level="cleanScentLevel"
            icon="square"
            class="product-thumb-clean-scents"
          />
        </template>
      </div>
      <div
        v-if="['default', 'blank'].includes(layout) && productLabels.length"
        class="product-thumb-labels"
      >
        <ProductLabel
          v-for="label in productLabels"
          :key="label.name"
          class="product-thumb-label"
          :type="label.slug"
          aria-hidden="true"
          :text="label.name"
        />
      </div>

      <ProductPicture
        :discontinued="isDiscontinued && ['default', 'blank'].includes(layout)"
        html-role="none"
        loading="lazy"
        class="product-thumb-image"
        :image="{ src: src, alt: alt, srcset: srcset }"
        :sizes="sizes"
      />
    </div>

    <div class="content" :class="!priceRange.length && !rating && 'no-meta'">
      <div class="product-thumb-name" itemprop="name">
        {{ name }}<span class="at-text">.</span>
      </div>
      <span v-for="label in productLabels" :key="label.name" class="at-text">
        This product is {{ label }}.
      </span>
      <span v-if="cleanScentLevel != 'not_clean'" class="at-text">
        This is a CleanScent Fragrance.
      </span>
      <template v-if="!(layout === 'small')">
        <div class="product-thumb-meta">
          <Rating
            v-show="layout === 'default'"
            class="product-thumb-rating"
            :rating="rating"
            :review-count="reviewCount"
            itemprop="aggregateRating"
          />
          <template v-if="priceRange.length">
            <span class="at-text"> starting at ${{ priceRange[0] }} </span>
            <span v-if="price" class="price" aria-hidden="true">
              {{ price }}
            </span>
          </template>
        </div>
      </template>
      <span
        v-if="description && layout === 'review'"
        class="product-thumb-description"
      >
        {{ formatDesc }}
      </span>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
  import { createProductTags } from "~/lib/createProductTags.js"

  const authStore = useAuthStore()

  const cleanScentsPlusEnabled = computed(() => {
    return authStore.user?.featureFlags?.cleanScentsPlus
  })

  defineEmits(["onClick"])

  const props = withDefaults(
    defineProps<{
      alt: string
      isCleanScent?: boolean
      cleanScentLevel?: CsFragrance["cleanScentLevel"]
      description?: string
      gaCategory?: string
      href: string
      id: string | number
      isBestseller?: boolean
      isBlendingElement?: boolean
      isDiscontinued?: boolean
      isNew?: boolean
      isOnClearance?: boolean
      isOnSale?: boolean
      layout?: "default" | "small" | "card" | "review" | "blank"
      marketingTaxons?: SolidusProduct["marketingTaxons"]
      name: string
      priceRange: SolidusProduct["priceRange"]
      rating: number
      reviewCount?: number
      sizes?: string
      src?: SolidusProduct["imageUrl"]
      srcset: SolidusProduct["imageSrcset"]
      taxons?: SolidusProduct["taxons"]
    }>(),
    {
      alt: "",
      cleanScent: false,
      cleanScentLevel: "not_clean",
      description: "",
      gaCategory: "",
      isBestseller: false,
      isBlendingElement: false,
      isDiscontinued: false,
      isNew: false,
      isOnClearance: false,
      isOnSale: false,
      layout: "default",
      marketingTaxons: () => [],
      priceRange: () => [],
      rating: 0,
      reviewCount: 0,
      sizes: undefined,
      src: "",
      srcset: () => [],
      taxons: () => [],
    },
  )
  const productLabels = computed(() => {
    return createProductTags(props.marketingTaxons, props.isDiscontinued, 2)
  })

  const path = computed(() => {
    return props.href?.startsWith("/") ? props.href : `/${props.href}`
  })
  const formatDesc = computed(() => {
    return props.description.substring(0, 90) + "..."
  })
  const price = computed(() => {
    if (props.priceRange.length === 1) {
      return `$${parseFloat(props.priceRange[0], 8).toFixed(2)}`
    }
    return `$${parseFloat(props.priceRange[0], 8).toFixed(2)}+`
  })
  const isBlendingTaxon = computed(() => {
    return props.taxons?.find((taxon) => taxon.id === "400")
  })
</script>

<style lang="scss" scoped>
  .product-thumb {
    color: inherit;
    text-decoration: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &:hover {
      .product-thumb-image-wrap {
        transform: scale(1.02) translate3d(0, 0, 0) perspective(1px)
          translateZ(0);
        box-shadow: $hover-shadow;
      }
    }
  }

  .product-thumb-image-wrap {
    position: relative;
    background: white;
    box-shadow: $shadow;
    border-radius: $border-radius-large;
    transition:
      transform 120ms ease-in-out,
      box-shadow 120ms ease-in-out;
    transform-origin: 50% 50%;
    will-change: transform, box-shadow;
    &-circle {
      border-radius: 50%;
    }
  }

  .product-thumb-image {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    overflow: hidden;
  }

  .product-thumb-highlights {
    position: absolute;
    display: flex;
    flex-direction: column;
    grid-gap: calc($base-spacing / 2);
    z-index: 5;
    right: $base-spacing * 3;
    top: $base-spacing * 3;
  }

  .product-thumb-clean-scents {
    overflow: hidden;
    display: block;
  }

  .product-thumb-labels {
    display: flex;
    flex-direction: column;
    gap: $base-spacing;
    align-items: flex-end;
    position: absolute;
    left: $base-spacing * 3;
    right: $base-spacing * 3;
    bottom: $base-spacing * 3;
    z-index: 5;
  }

  .product-thumb-meta {
    display: flex;
    grid-gap: $base-spacing;
    align-items: center;
  }

  .product-thumb-name {
    max-width: 100%;
    @apply text-sm leading-normal;

    .product-thumb-layout-blank & {
      width: 100%;
      overflow: hidden;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    &:after {
      clear: both;
    }
  }

  .product-thumb-states {
    margin-bottom: $base-spacing;
    display: flex;
    grid-gap: $base-spacing;
    flex-flow: row wrap;
  }

  /* Default */
  .product-thumb-layout-default,
  .product-thumb-layout-blank {
    display: flex;
    flex-direction: column;

    .product-thumb-image-wrap {
      aspect-ratio: 1 / 1;
      width: 100%;
    }

    .product-thumb-name {
      font-weight: bold;
      align-items: center;
      grid-gap: $base-spacing;
    }

    .content {
      box-sizing: border-box;
      display: flex;
      flex: 1;
      padding: $base-spacing * 3 0;
      flex-direction: column;
      grid-gap: calc($base-spacing / 2);
      align-items: flex-start;

      min-height: $base-spacing * 20;
      &.no-meta {
        min-height: $base-spacing * 8;
      }
    }
    .price {
      margin-right: calc($base-spacing / 2);
      color: $gray-text;
      @apply text-sm leading-normal;
      transform: translateY(1px);
    }
  }

  /* Card */
  .product-thumb-layout-card {
    display: inline-grid;
    grid-template-columns: 64px 1fr;
    border-radius: $border-radius;
    background: white;
    box-shadow: $shadow;
    padding-right: $base-spacing * 3;
    grid-gap: $base-spacing * 4;
    .product-thumb-name {
      font-weight: bold;
    }
    .product-thumb-image {
      border-radius: $border-radius 0 0 $border-radius;

      &:hover {
        transform: initial;
        box-shadow: initial;
      }
    }
    .product-thumb-image-wrap {
      height: 100%;
      aspect-ratio: 1 / 1;
    }
    .content {
      margin: $base-spacing * 2 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  /* Small */
  .product-thumb-layout-small {
    display: flex;
    align-items: center;
    background: white;
    font-weight: bold;
    font-family: $font-family-default;
    .product-thumb-image-wrap {
      flex-shrink: 0;
      width: $base-spacing * 9;
      height: $base-spacing * 9;
      margin-right: $base-spacing * 3;
    }
    .product-thumb-name {
      padding-right: $base-spacing * 2;
    }
  }

  /* Review */
  .product-thumb-layout-review {
    display: flex;
    align-items: center;
    grid-gap: $space-s;
    background-color: $blue-100;
    border-radius: $border-radius;
    padding: $base-spacing * 8 $base-spacing * 6;
    .product-thumb-image-wrap {
      flex-shrink: 0;
      flex-grow: 1;
      width: $base-spacing * 16;
      height: $base-spacing * 16;
    }
    .product-thumb-name {
      font-weight: bold;
    }
    .content {
      display: block;
      padding-bottom: 0px;
    }
    .product-thumb-description {
      color: $gray;
    }
  }

  .at-text {
    @extend %at-text;
  }
</style>
